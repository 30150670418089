module.exports = {
    configs: {
        recommended: {
            rules: {
                'bpmnlint/no-complex-gateway': 'error',
                'modelervs/no-send-task': 'error',
                "modelervs/no-script-task": "error",
                "modelervs/no-call-activity": "error",
                'modelervs/no-receive-task': 'error',
                'modelervs/no-boundary-event': 'error',
                'modelervs/no-signal-start': 'error',
                'modelervs/no-message-throw-intermediate': 'error',
                'modelervs/no-escalation-throw-intermediate': 'error',
                'modelervs/no-link-catch-intermediate': 'error',
                'modelervs/no-link-throw-intermediate': 'error',
                'modelervs/no-compensation-throw-intermediate': 'error',
                'modelervs/no-escalation-end': 'error',
                'modelervs/no-error-end': 'error',
                'modelervs/no-compensation-end': 'error',
                'modelervs/no-signal-end': 'error',
                'modelervs/no-terminate-end': 'error',
                'bpmnlint/single-event-definition': 'error',
                // 'modelervs/no-loop': 'error',
                // 'modelervs/no-multiple-instance-parallel': 'error',
                // 'modelervs/no-multiple-instance-sequential': 'error',
                'modelervs/no-event-subprocess': 'error',
                'modelervs/no-expanded-subprocess': 'error',
                'modelervs/no-collapsed-pool': 'error',
                'modelervs/label-required': 'error',
                'modelervs/label-data-object': 'error',
                'modelervs/label-data-store': 'error',
                'modelervs/label-message-flow': 'warning',
                'modelervs/label-branching-gateway': 'error',
                'modelervs/label-branching-sequence-flow': 'error',
                'bpmnlint/fake-join': 'error',
                'bpmnlint/superfluous-gateway': 'error',
                'modelervs/connected-elements': 'error',
                'modelervs/connected-message-elements': 'error',
                'bpmnlint/no-duplicate-sequence-flows': 'error',
                'bpmnlint/no-gateway-join-fork': 'error',
                'bpmnlint/single-blank-start-event': 'error',
                'modelervs/start-event-required': 'error',
                'modelervs/maximum-15-activities': 'warn',
                'modelervs/end-event-required': 'error',
            }
        }
    }
}
