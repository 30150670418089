import BpmnRenderer from "bpmn-js/lib/draw/BpmnRenderer";
import inherits from 'inherits';

//import {getModelerVSValue} from "../../UtilsVS";
function getProperty(businessObject, type) {
  if (!businessObject.extensionElements) {
    return;
  }
  else if(!businessObject.extensionElements.values){
    businessObject.extensionElements.values=[];
    return;
  }
  return businessObject.extensionElements.values.filter((extensionElement) => {
    return extensionElement.name==type;
  })[0];
}

export default function RendererVS (config, eventBus, styles, pathMap, canvas, textRenderer) {
    BpmnRenderer.call(this, config, eventBus, styles, pathMap, canvas, textRenderer, 2000)
    const originalDrawShape = this.drawShape
    this.drawShape = (parentGfx, element) => {
        if(config.LANG && config.LANG === 'de_DE') {
            const textDE = getProperty(element.businessObject, "nameDE");

            if(textDE &&textDE.value){
              if(element.businessObject.name){
                element.businessObject.set('name', textDE.value);
              }
              else if(element.businessObject.text){
                element.businessObject.set('text', textDE.value);
              }
            }
            else if (textDE) {

            }
        }
        const renderedElement =  originalDrawShape.call(this, parentGfx, element)

        return renderedElement
    }
}



inherits(RendererVS, BpmnRenderer)

RendererVS.$inject = [
    'config',
    'eventBus',
    'styles',
    'pathMap',
    'canvas',
    'textRenderer',
];
