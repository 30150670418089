import { isTextFieldEntryEdited, isSelectEntryEdited  } from '@bpmn-io/properties-panel';
import { useService } from 'bpmn-js-properties-panel';
import {TextComponent, TypComponent} from '../../ProviderUtil'
import {is, isAny} from 'bpmn-js/lib/util/ModelUtil';

export default function(element) {
  var vsproperties = [];

  //If the element is a loop
  if(is(element, 'bpmn:Activity') && element.di.bpmnElement.loopCharacteristics){
    vsproperties.push({
      id: 'exitCondition',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
  }
  if(isAny(element, [ 'bpmn:BusinessRuleTask', 'bpmn:Task'])){
    vsproperties.push({
        id: 'itSystem',
        element,
        component: TextComponent,
        isEdited: isTextFieldEntryEdited
      });
    vsproperties.push({
      id: 'risk',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
    vsproperties.push({
      id: 'control',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });

    if (is(element, 'bpmn:BusinessRuleTask')) {
      vsproperties.push(
        {
          id: 'decisionName',
          element,
          component: TextComponent,
          isEdited: isTextFieldEntryEdited
        }
      );
      vsproperties.push({
        id: 'dmnModel',
        element,
        label: 'DMN Diagram',
        component: TextComponent,
        isEdited: isTextFieldEntryEdited
      })
    }
  }

  if(isAny(element, ['bpmn:CallActivity', 'bpmn:SubProcess', 'bpmn:Participant']) ) {
    vsproperties.push({
      id: 'bpmnModel',
      label: "BPMN Model",
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
  }
  if(is(element, 'bpmn:DataObjectReference')) {
    vsproperties.push({
      id: 'referencedDocument',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
    
    vsproperties.push({
      id: 'referencedDocumentDE',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });

    //Different from the rest! Selection dropdown, options declared in the component
    vsproperties.push({
      id: 'typ',
      element,
      component: TypComponent,
      isEdited: isSelectEntryEdited
    });

  }
  if(is(element, 'bpmn:Participant')) {
    vsproperties.push({
      id: 'risk',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
    vsproperties.push({
      id: 'control',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
    vsproperties.push({
      id: 'processOwner',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
    vsproperties.push({
      id: 'releaseDate',
      element,
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
  }

  
  return vsproperties;
}
