//Collection of small helper methods, moved here from index.js for readability


export async function downloadXml(modeler, articleId){
	let result = await modeler.saveXML();
	const xmlText = result.xml
	let a = document.createElement('a')
	const blob = new Blob([xmlText], { type: 'text/plain' })
	a.setAttribute('href', window.URL.createObjectURL(blob))
	a.setAttribute('download', "diagram_"+articleId+".bpmn")
	a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':')
	a.draggable = true
	a.classList.add('dragout')
	a.click()
}

export function getFileAsText(fileUrl) {
    return new Promise((resolve, reject) => {
        fetch(fileUrl)
        .then((response) => response.text())
        .then((textFile) => {
            resolve(textFile);
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export function initialFile(type = undefined){

  if (type =="DMN"){
    return "";
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
          <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1s8m89i" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="2.2.4">
            <bpmn:process id="Process_1" isExecutable="true">
              <bpmn:startEvent id="StartEvent_1" />
            </bpmn:process>
            <bpmndi:BPMNDiagram id="BPMNDiagram_1">
              <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
                <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                  <dc:Bounds x="173" y="102" width="36" height="36" />
                </bpmndi:BPMNShape>
              </bpmndi:BPMNPlane>
            </bpmndi:BPMNDiagram>
          </bpmn:definitions>`;
}

export function readFile(event){
	readFileInput(event)
		.then(
			(data) => {
				//Todo modeler here is incorect
				localData.save(undefined, data, STORAGE_KEY)
					.then(
						//location.reload()
					);
			}
		);
};

export function saveFile(e){

	return new Promise((resolve) => {

		const [file] = e.target.files;

		const reader = new FileReader();
		reader.addEventListener("load", () => {
			resolve(reader.result);
		}, false);

		if (file) {
			reader.readAsText(file);
		}

	})

}

export function getUrlParameter(sParam){
    let pageURL = window.location.search.substring(1),
        sURLVariables = pageURL.split('&'),
        parameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        parameterName = sURLVariables[i].split('=');

        if (parameterName[0] === sParam) {
            return parameterName[1] === undefined ? true : decodeURIComponent(parameterName[1]);
        }
    }
};

export function toUTF8Array (str) {
    let utf8 = [];
    for (let i=0; i < str.length; i++) {
        let charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
            utf8.push(0xc0 | (charcode >> 6),
                0x80 | (charcode & 0x3f));
        }
        else if (charcode < 0xd800 || charcode >= 0xe000) {
            utf8.push(0xe0 | (charcode >> 12),
                0x80 | ((charcode>>6) & 0x3f),
                0x80 | (charcode & 0x3f));
        }
        // surrogate pair
        else {
            i++;
            // UTF-16 encodes 0x10000-0x10FFFF by
            // subtracting 0x10000 and splitting the
            // 20 bits of 0x0-0xFFFFF into two halves
            charcode = 0x10000 + (((charcode & 0x3ff)<<10)
                | (str.charCodeAt(i) & 0x3ff));
            utf8.push(0xf0 | (charcode >>18),
                0x80 | ((charcode>>12) & 0x3f),
                0x80 | ((charcode>>6) & 0x3f),
                0x80 | (charcode & 0x3f));
        }
    }
    return utf8;
}
