
const cache = {};

/**
 * A resolver that caches rules and configuration as part of the bundle,
 * making them accessible in the browser.
 *
 * @param {Object} cache
 */
function Resolver() {}

Resolver.prototype.resolveRule = function(pkg, ruleName) {

  const rule = cache[pkg + '/' + ruleName];

  if (!rule) {
    throw new Error('cannot resolve rule <' + pkg + '/' + ruleName + '>');
  }

  return rule;
};

Resolver.prototype.resolveConfig = function(pkg, configName) {
  throw new Error(
    'cannot resolve config <' + configName + '> in <' + pkg +'>'
  );
};

const resolver = new Resolver();

const rules = {
  "no-complex-gateway": "error",
  "modelervs/no-send-task": "error",
  "modelervs/no-script-task": "error",
  "modelervs/no-receive-task": "error",
  "modelervs/no-boundary-event": "error",
  "modelervs/no-signal-start": "error",
  "modelervs/no-message-throw-intermediate": "warn",
  "modelervs/no-escalation-throw-intermediate": "error",
  "modelervs/no-link-catch-intermediate": "error",
  "modelervs/no-link-throw-intermediate": "error",
  "modelervs/no-compensation-throw-intermediate": "error",
  "modelervs/no-escalation-end": "error",
  "modelervs/no-error-end": "error",
  "modelervs/no-compensation-end": "error",
  "modelervs/no-signal-end": "error",
  "modelervs/no-terminate-end": "error",
  "single-event-definition": "error",
  "modelervs/no-event-subprocess": "error",
  "modelervs/no-expanded-subprocess": "error",
  "modelervs/no-collapsed-pool": "error",
  "modelervs/label-required": "error",
  "modelervs/label-data-object": "error",
  "modelervs/label-data-store": "error",
  "modelervs/label-message-flow": "warn",
  "modelervs/label-branching-gateway": "error",
  "modelervs/label-branching-sequence-flow": "error",
  "fake-join": "error",
  "superfluous-gateway": "error",
  "modelervs/connected-elements": "error",
  "modelervs/connected-message-elements": "error",
  "no-duplicate-sequence-flows": "error",
  "no-gateway-join-fork": "error",
  "single-blank-start-event": "error",
  "modelervs/start-event-required": "error",
  "modelervs/maximum-15-activities": "warn",
  "modelervs/end-event-required": "error",
  "modelervs/bpmn-model-required": "warn",
  "modelervs/dmn-model-required": "error",
  "modelervs/loop-exit-condition-required": "error",
  "modelervs/no-adhoc-start-end-event": "error",
  "modelervs/no-bpmn-model": "warn",
  "modelervs/no-business-rule-task": "warn",
  "modelervs/no-condition-start": "warn",
  "modelervs/no-condition-intermediate": "warn",
  "modelervs/no-event-based-gateway": "warn",
  "modelervs/no-inclusive-gateway": "warn",
  "modelervs/no-loop": "warn",
  "modelervs/no-manual-task": "warn",
  "modelervs/no-message-end": "warn",
  "modelervs/no-multiple-instance-parallel": "warn",
  "modelervs/no-multiple-instance-sequential": "warn",
  "modelervs/no-service-task": "warn",
  "modelervs/no-signal-intermediate-catch": "error",
  "modelervs/no-signal-intermediate-throw": "warn",
  "modelervs/no-user-task": "warn"
};

const config = {
  rules: rules
};

const bundle = {
  resolver: resolver,
  config: config
};

export { resolver, config };

export default bundle;



import rule_0 from 'bpmnlint/rules/no-complex-gateway';
cache['bpmnlint/no-complex-gateway'] = rule_0;

import rule_1 from 'bpmnlint-plugin-modelervs/rules/no-send-task';
cache['bpmnlint-plugin-modelervs/no-send-task'] = rule_1;

import rule_2 from 'bpmnlint-plugin-modelervs/rules/no-script-task';
cache['bpmnlint-plugin-modelervs/no-script-task'] = rule_2;

import rule_3 from 'bpmnlint-plugin-modelervs/rules/no-receive-task';
cache['bpmnlint-plugin-modelervs/no-receive-task'] = rule_3;

import rule_4 from 'bpmnlint-plugin-modelervs/rules/no-boundary-event';
cache['bpmnlint-plugin-modelervs/no-boundary-event'] = rule_4;

import rule_5 from 'bpmnlint-plugin-modelervs/rules/no-signal-start';
cache['bpmnlint-plugin-modelervs/no-signal-start'] = rule_5;

import rule_6 from 'bpmnlint-plugin-modelervs/rules/no-message-throw-intermediate';
cache['bpmnlint-plugin-modelervs/no-message-throw-intermediate'] = rule_6;

import rule_7 from 'bpmnlint-plugin-modelervs/rules/no-escalation-throw-intermediate';
cache['bpmnlint-plugin-modelervs/no-escalation-throw-intermediate'] = rule_7;

import rule_8 from 'bpmnlint-plugin-modelervs/rules/no-link-catch-intermediate';
cache['bpmnlint-plugin-modelervs/no-link-catch-intermediate'] = rule_8;

import rule_9 from 'bpmnlint-plugin-modelervs/rules/no-link-throw-intermediate';
cache['bpmnlint-plugin-modelervs/no-link-throw-intermediate'] = rule_9;

import rule_10 from 'bpmnlint-plugin-modelervs/rules/no-compensation-throw-intermediate';
cache['bpmnlint-plugin-modelervs/no-compensation-throw-intermediate'] = rule_10;

import rule_11 from 'bpmnlint-plugin-modelervs/rules/no-escalation-end';
cache['bpmnlint-plugin-modelervs/no-escalation-end'] = rule_11;

import rule_12 from 'bpmnlint-plugin-modelervs/rules/no-error-end';
cache['bpmnlint-plugin-modelervs/no-error-end'] = rule_12;

import rule_13 from 'bpmnlint-plugin-modelervs/rules/no-compensation-end';
cache['bpmnlint-plugin-modelervs/no-compensation-end'] = rule_13;

import rule_14 from 'bpmnlint-plugin-modelervs/rules/no-signal-end';
cache['bpmnlint-plugin-modelervs/no-signal-end'] = rule_14;

import rule_15 from 'bpmnlint-plugin-modelervs/rules/no-terminate-end';
cache['bpmnlint-plugin-modelervs/no-terminate-end'] = rule_15;

import rule_16 from 'bpmnlint/rules/single-event-definition';
cache['bpmnlint/single-event-definition'] = rule_16;

import rule_17 from 'bpmnlint-plugin-modelervs/rules/no-event-subprocess';
cache['bpmnlint-plugin-modelervs/no-event-subprocess'] = rule_17;

import rule_18 from 'bpmnlint-plugin-modelervs/rules/no-expanded-subprocess';
cache['bpmnlint-plugin-modelervs/no-expanded-subprocess'] = rule_18;

import rule_19 from 'bpmnlint-plugin-modelervs/rules/no-collapsed-pool';
cache['bpmnlint-plugin-modelervs/no-collapsed-pool'] = rule_19;

import rule_20 from 'bpmnlint-plugin-modelervs/rules/label-required';
cache['bpmnlint-plugin-modelervs/label-required'] = rule_20;

import rule_21 from 'bpmnlint-plugin-modelervs/rules/label-data-object';
cache['bpmnlint-plugin-modelervs/label-data-object'] = rule_21;

import rule_22 from 'bpmnlint-plugin-modelervs/rules/label-data-store';
cache['bpmnlint-plugin-modelervs/label-data-store'] = rule_22;

import rule_23 from 'bpmnlint-plugin-modelervs/rules/label-message-flow';
cache['bpmnlint-plugin-modelervs/label-message-flow'] = rule_23;

import rule_24 from 'bpmnlint-plugin-modelervs/rules/label-branching-gateway';
cache['bpmnlint-plugin-modelervs/label-branching-gateway'] = rule_24;

import rule_25 from 'bpmnlint-plugin-modelervs/rules/label-branching-sequence-flow';
cache['bpmnlint-plugin-modelervs/label-branching-sequence-flow'] = rule_25;

import rule_26 from 'bpmnlint/rules/fake-join';
cache['bpmnlint/fake-join'] = rule_26;

import rule_27 from 'bpmnlint/rules/superfluous-gateway';
cache['bpmnlint/superfluous-gateway'] = rule_27;

import rule_28 from 'bpmnlint-plugin-modelervs/rules/connected-elements';
cache['bpmnlint-plugin-modelervs/connected-elements'] = rule_28;

import rule_29 from 'bpmnlint-plugin-modelervs/rules/connected-message-elements';
cache['bpmnlint-plugin-modelervs/connected-message-elements'] = rule_29;

import rule_30 from 'bpmnlint/rules/no-duplicate-sequence-flows';
cache['bpmnlint/no-duplicate-sequence-flows'] = rule_30;

import rule_31 from 'bpmnlint/rules/no-gateway-join-fork';
cache['bpmnlint/no-gateway-join-fork'] = rule_31;

import rule_32 from 'bpmnlint/rules/single-blank-start-event';
cache['bpmnlint/single-blank-start-event'] = rule_32;

import rule_33 from 'bpmnlint-plugin-modelervs/rules/start-event-required';
cache['bpmnlint-plugin-modelervs/start-event-required'] = rule_33;

import rule_34 from 'bpmnlint-plugin-modelervs/rules/maximum-15-activities';
cache['bpmnlint-plugin-modelervs/maximum-15-activities'] = rule_34;

import rule_35 from 'bpmnlint-plugin-modelervs/rules/end-event-required';
cache['bpmnlint-plugin-modelervs/end-event-required'] = rule_35;

import rule_36 from 'bpmnlint-plugin-modelervs/rules/bpmn-model-required';
cache['bpmnlint-plugin-modelervs/bpmn-model-required'] = rule_36;

import rule_37 from 'bpmnlint-plugin-modelervs/rules/dmn-model-required';
cache['bpmnlint-plugin-modelervs/dmn-model-required'] = rule_37;

import rule_38 from 'bpmnlint-plugin-modelervs/rules/loop-exit-condition-required';
cache['bpmnlint-plugin-modelervs/loop-exit-condition-required'] = rule_38;

import rule_39 from 'bpmnlint-plugin-modelervs/rules/no-adhoc-start-end-event';
cache['bpmnlint-plugin-modelervs/no-adhoc-start-end-event'] = rule_39;

import rule_40 from 'bpmnlint-plugin-modelervs/rules/no-bpmn-model';
cache['bpmnlint-plugin-modelervs/no-bpmn-model'] = rule_40;

import rule_41 from 'bpmnlint-plugin-modelervs/rules/no-business-rule-task';
cache['bpmnlint-plugin-modelervs/no-business-rule-task'] = rule_41;

import rule_42 from 'bpmnlint-plugin-modelervs/rules/no-condition-start';
cache['bpmnlint-plugin-modelervs/no-condition-start'] = rule_42;

import rule_43 from 'bpmnlint-plugin-modelervs/rules/no-condition-intermediate';
cache['bpmnlint-plugin-modelervs/no-condition-intermediate'] = rule_43;

import rule_44 from 'bpmnlint-plugin-modelervs/rules/no-event-based-gateway';
cache['bpmnlint-plugin-modelervs/no-event-based-gateway'] = rule_44;

import rule_45 from 'bpmnlint-plugin-modelervs/rules/no-inclusive-gateway';
cache['bpmnlint-plugin-modelervs/no-inclusive-gateway'] = rule_45;

import rule_46 from 'bpmnlint-plugin-modelervs/rules/no-loop';
cache['bpmnlint-plugin-modelervs/no-loop'] = rule_46;

import rule_47 from 'bpmnlint-plugin-modelervs/rules/no-manual-task';
cache['bpmnlint-plugin-modelervs/no-manual-task'] = rule_47;

import rule_48 from 'bpmnlint-plugin-modelervs/rules/no-message-end';
cache['bpmnlint-plugin-modelervs/no-message-end'] = rule_48;

import rule_49 from 'bpmnlint-plugin-modelervs/rules/no-multiple-instance-parallel';
cache['bpmnlint-plugin-modelervs/no-multiple-instance-parallel'] = rule_49;

import rule_50 from 'bpmnlint-plugin-modelervs/rules/no-multiple-instance-sequential';
cache['bpmnlint-plugin-modelervs/no-multiple-instance-sequential'] = rule_50;

import rule_51 from 'bpmnlint-plugin-modelervs/rules/no-service-task';
cache['bpmnlint-plugin-modelervs/no-service-task'] = rule_51;

import rule_52 from 'bpmnlint-plugin-modelervs/rules/no-signal-intermediate-catch';
cache['bpmnlint-plugin-modelervs/no-signal-intermediate-catch'] = rule_52;

import rule_53 from 'bpmnlint-plugin-modelervs/rules/no-signal-intermediate-throw';
cache['bpmnlint-plugin-modelervs/no-signal-intermediate-throw'] = rule_53;

import rule_54 from 'bpmnlint-plugin-modelervs/rules/no-user-task';
cache['bpmnlint-plugin-modelervs/no-user-task'] = rule_54;