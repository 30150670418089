import localData from '../vshelper/localstorage.js';
import {
  downloadXml,
  getFileAsText,
  initialFile,
  toUTF8Array,
  getUrlParameter,
  readFile,
  saveFile
} from '../vshelper/iohelper.js';
import tooltips from "diagram-js/lib/features/tooltips";

const ARTICLE_ID = getUrlParameter("articleid");
const STORAGE_KEY = "diagram_" + ARTICLE_ID;
const INITIAL_FILE = initialFile();

//BPMN Viewer
export default function viewer(viewer) {

  const openViewing = async (bpmnXML) => {
    await viewer.importXML(bpmnXML);
    viewer.get('canvas').zoom('fit-viewport');
  }
  let LfrConnector;
  if (typeof Liferay != "undefined") {
    console.log("Liferay code goes here!")
  }
  else {
    //Testing environment

    localData.get(STORAGE_KEY)
      .then((data) => {
        if (data) {
          console.log('Fetched from localstorage at ' + STORAGE_KEY);
          openViewing(data);
        } else {
          console.log("Initializing new drawing");
          openViewing(data);
        }
      })
      .catch((e) => {
        console.log(`Error loading data: \n ${e} \n Initialize new drawing !`);
        openViewing(data);
      });

    console.log("Testing tooltips");


    var buttons = document.getElementById("buttons");
    buttons.innerHTML = "";
    var return_button = document.createElement("button");
    return_button.innerHTML = "Go back";
    return_button.onclick = function () {
      location.href = location.href.replace("&viewonly=true", "")
    }
    var print_button = document.createElement("button");
    print_button.innerHTML = "Print";
    print_button.onclick = function () {
    }

    buttons.appendChild(return_button);
    buttons.appendChild(print_button);

  }

}
