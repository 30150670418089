
//NB: When adding new properties, also update descriptors/modelerVS.json

import nameProps from './parts/NameProps';
import docProps from './parts/DocumentationProps';
import vsProps from './parts/VSProps';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import {createGroup} from '../ProviderUtil'

const LOW_PRIORITY = 500;


/**
 * A provider with a `#getGroups(element)` method
 * that exposes groups for a diagram element.
 *
 * @param {PropertiesPanel} propertiesPanel
 * @param {Function} translate
 */
export default function VSPropertiesProvider(propertiesPanel, translate) {


  /**
   * Return the groups provided for the given element.
   *
   * @param {DiagramElement} element
   *
   * @return {(Object[]) => (Object[])} groups middleware
   */
  this.getGroups = function(element) {
    return function(groups) {
        const genGroup = groups[0];//createGroup("General",element, translate);
        const docGroup = groups[1];//createGroup("Documentation", element, translate);
        const vsGroup = createGroup("VS", element, translate);

        //Hide the default elements that we don't want for modelerVS
        var elemId = genGroup.entries.findIndex(({id}) => id =="id");
        if(elemId > -1)
          genGroup.entries.splice(elemId, 1);
        var elemId = genGroup.entries.findIndex(({id}) => id =="processId");
        if(elemId > -1)
          genGroup.entries.splice(elemId, 1);
        var elemId = genGroup.entries.findIndex(({id}) => id =="processName");
        if(elemId > -1)
          genGroup.entries.splice(elemId, 1);
        elemId = genGroup.entries.findIndex(({id}) => id =="isExecutable");
        if(elemId > -1)
          genGroup.entries.splice(elemId, 1);
        elemId = docGroup.entries.findIndex(({id}) => id =="processDocumentation");
        if(elemId > -1)
          docGroup.entries.splice(elemId, 1);

        genGroup.entries = genGroup.entries.concat(nameProps(element));
        docGroup.entries = docGroup.entries.concat(docProps(element));
        vsGroup.entries = vsProps(element);

        if(vsGroup.entries.length >0){
          groups.push(vsGroup);
        }

      return groups;
    }
  };

  // registration ////////



  // Register our custom magic properties provider.
  // Use a lower priority to ensure it is loaded after
  // the basic BPMN properties.
  propertiesPanel.registerProvider(LOW_PRIORITY, this);


}


VSPropertiesProvider.$inject = [ 'propertiesPanel', 'translate' ];




//Set the dictionary
