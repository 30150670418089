module.exports = {
  "name": "ModelerVS",
  "prefix": "modelervs",
  "uri": "http://",
  "xml": {
    "tagAlias": "lowerCase"
  },
  "associations": [],
  "types": [
    {
      "name": "property",
      "superClass": [
        "Element"
      ],
      "properties": [
        {
          "name": "name",
          "type": "String",
          "isAttr": true
        },
        {
          "name": "value",
          "type": "String",
          "isAttr": true
        }
      ]
    },
    {
      "name": "DMNElement",
      "superClass": [
        "Element"
      ],
      "properties": [
        {
          "name": "documentation",
          "type": "String",
          "isAttr": true
        },
        {
          "name": "referencedDocument",
          "type": "String",
          "isAttr": true
        }
      ]
    }
  ]
}
;