import { TextFieldEntry, isTextFieldEntryEdited } from '@bpmn-io/properties-panel';
import { useService } from 'bpmn-js-properties-panel';
import {TextComponent, getProperty} from '../../ProviderUtil'
import {is} from 'bpmn-js/lib/util/ModelUtil';

export default function(element) {
  var nameproperties = [];

  //Add Name to Collab element
  if(is(element, 'bpmn:Collaboration')){
    nameproperties.push({
        id: 'name',
        element,
        label: 'Name',
        modelProperty: 'name',
        component: CollabName,
        isEdited: isTextFieldEntryEdited,
    })
  }

  nameproperties.push({
      id: 'nameDE',
      element,
      label: 'Deutscher Name',
      modelProperty: 'nameDE',
      component: TextComponent,
      isEdited: isTextFieldEntryEdited
    });
  return nameproperties;
}


export function CollabName(props) {
  const { element, id } = props;

  const modeling = useService('modeling');
  const translate = useService('translate');
  const debounce = useService('debounceInput');
  const moddle = useService('moddle');
  const elemReg = useService('elementRegistry')
  const getValue = () => {
    var ext = element.businessObject.extensionElements;
    if (!ext) {
      return [];
    }

    let prop = getProperty(element.businessObject, id);

    if(!prop ||prop.length<1){
      return [];
    }
    return prop.value;
    //return element.businessObject.nameDE || '';
  }


  const setValue = value => {
    const extensionElements = element.businessObject.extensionElements || moddle.create('bpmn:ExtensionElements')
    let prop = getProperty(element.businessObject, id);

    if (!prop) {
      prop = moddle.create('modelervs:property', {name:id, value:value});
      extensionElements.get('values').push(prop);
    }
    prop.value = value;
  // var process = definitions.rootElements.find(p => p.$type == "bpmn:Process")
   return modeling.updateProperties(element, {
     extensionElements
   });
  }
  var ff = props.label || prettyLabel(id)
  return <TextFieldEntry
    id={ id }
    element={ element }
    label={ translate(ff) }
    getValue={ getValue }
    setValue={ setValue }
    debounce={ debounce }
  />
}
