const LfrFileEntry = class {
    constructor(groupId, folderId, fileTitle) {
        this.groupId = groupId;
        this.folderId = folderId;
        this.fileTitle = fileTitle;
        
    }

    addFile(bytes) {
        const method = '/dlapp/add-file-entry';
        let data = {
            repositoryId: this.groupId,
            folderId: this.folderId,
            sourceFileName: this.fileTitle,
            mimeType: '',
            title: this.fileTitle,
            description: '',
            changeLog: '',
            /*majorVersion: false,*/
            bytes: bytes
        }   
        return new Promise((resolve, reject) => {
            Liferay.Service(
                method,
                data,
                (obj) => {
                    this.fileEntry = obj;
                    resolve(obj)
                },
                (e) => {
                    reject(e)
                }
            )
        })
    }

    updateFile(bytes) {
        const method = '/dlapp/update-file-entry';
        let data = {
            fileEntryId: this.fileEntry.fileEntryId,
            sourceFileName: this.fileTitle,
            mimeType: '',
            title: this.fileTitle,
            description: '',
            changeLog: '',
            majorVersion: false,
            bytes: bytes
        }  
        return new Promise((resolve, reject) => {
            Liferay.Service(
                method,
                data,
                (obj) => {
                    this.fileEntry = obj;
                    resolve(obj)
                },
                (e) => {
                    reject(e)
                }
            )
        })
    }

    getFile() {
        const method = '/dlfileentry/get-file-entry';
        let data = {
            groupId: this.groupId,
            folderId: this.folderId,
            title: this.fileTitle
        }
        return new Promise((resolve, reject) => {
            Liferay.Service(
                method,
                data,
                (obj) => {
                    this.fileEntry = obj;
                    resolve(obj)
                },
                (e) => {
                    reject(e)
                }
            );
        })
    }

    getLatestFileVersion(published = true) {
        const method = '/dlfileversion/get-file-versions';
        let data = {
            fileEntryId: this.fileEntry.fileEntryId, 
            status: -1
        }
        return new Promise((resolve) => {
            Liferay.Service(
                method,
                data,
                (objs) => {
                    let latest = objs[0]
                    if (published) {
                        latest = objs.find(version => version.version === this.fileEntry.version)
                    }
                    resolve(latest)
                }
            )
        })
    }

    updateFileStatus(userId, vesriontoUpdate, saveStatus) { // Status 2 for 
        const method = '/dlfileentry/update-status';
        console.table(vesriontoUpdate)
        let data = {
            userId: userId,
			fileVersionId: vesriontoUpdate.fileVersionId,
			status: saveStatus,
			workflowContext: {}
        }
        return new Promise((resolve) => {
            Liferay.Service(
                method,
                data,
                (obj) => {
                    resolve(obj)
                }
            )
        })
    }

}

export default LfrFileEntry