import { isTextAreaEntryEdited } from '@bpmn-io/properties-panel';
import { useService } from 'bpmn-js-properties-panel';
import {TextComponent, TextAreaComponent} from '../../ProviderUtil'

export default function(element) {
  return [{
      id: 'elementDocumentationDE',
      element,
      component: TextAreaComponent,
      isEdited: isTextAreaEntryEdited
    }];
}
function getProperty(businessObject, type) {
  if (!businessObject.extensionElements) {
    return;
  }

  return businessObject.extensionElements.values.filter((extensionElement) => {
    return extensionElement.name==type;
  })[0];
}
