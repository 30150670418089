import { TextFieldEntry, isTextFieldEntryEdited, SelectEntry, isSelectEntryEdited, TextAreaEntry, isTextAreaEntryEdited  } from '@bpmn-io/properties-panel';
import { useService } from 'bpmn-js-properties-panel';

export function TextComponent(props) {
  const { element, id } = props;

  const modeling = useService('modeling');
  const translate = useService('translate');
  const debounce = useService('debounceInput');
  const moddle = useService('moddle');
  const getValue = () => {
    var ext = element.businessObject.extensionElements;
    if (!ext) {
      return [];
    }

    let prop = getProperty(element.businessObject, id);

    if(!prop ||prop.length<1){
      return [];
    }
    return prop.value;
    //return element.businessObject.nameDE || '';
  }


  const setValue = value => {
    const extensionElements = element.businessObject.extensionElements || moddle.create('bpmn:ExtensionElements')
    let prop = getProperty(element.businessObject, id);

    if (!prop) {
      prop = moddle.create('modelervs:property', {name:id, value:value});
      extensionElements.get('values').push(prop);
    }
    prop.value = value;

   return modeling.updateProperties(element, {
     extensionElements
   });
  }

  var label = props.label || prettyLabel(id);
  return <TextFieldEntry
    id={ id }
    element={ element }
    label={ translate(label) }
    getValue={ getValue }
    setValue={ setValue }
    debounce={ debounce }
  />
}

export function TextAreaComponent(props) {
  const { element, id } = props;

  const modeling = useService('modeling');
  const translate = useService('translate');
  const debounce = useService('debounceInput');
  const moddle = useService('moddle');

  //Not currently in use
  const resizeTextAreas = () => {
    var textareas = document.getElementsByTagName('textarea');
    for(let i=0; i<textareas.length; i++){
      var cols = textareas[i].cols;
      var linecount=0;
      var stringarray=textareas[i].value.split('\n')
      stringarray.forEach( (s) =>{
        linecount += Math.ceil( s.length / cols ); // Take into account long lines
      });
      textareas[i].rows = linecount+1;
    }
  }
  const getValue = () => {
    //resizeTextAreas();
    var ext = element.businessObject.extensionElements;
    if (!ext) {
      return [];
    }

    let prop = getProperty(element.businessObject, id);

    if(!prop ||prop.length<1){
      return [];
    }
    return prop.value;
  }
  const setValue = value => {
    const extensionElements = element.businessObject.extensionElements || moddle.create('bpmn:ExtensionElements')
    let prop = getProperty(element.businessObject, id);

    if (!prop) {
      prop = moddle.create('modelervs:property', {name:id, value:value});
      extensionElements.get('values').push(prop);
    }
    prop.value = value;

   return modeling.updateProperties(element, {
     extensionElements
   });
  }

  var label = props.label || prettyLabel(id);

  var f = <TextAreaEntry
    id={ id }
    element={ element }
    label={ translate(label) }
    getValue={ getValue }
    setValue={ setValue }
    debounce={ debounce }
  />;
  return f;
}

//Specific to a single dropdown field. TODO: Genericize with options as an argument
export function TypComponent(props){
  const { element, id } = props;

  const modeling = useService('modeling');
  const translate = useService('translate');
  const debounce = useService('debounceInput');
  const moddle = useService('moddle');

  const getValue = () => {
    var ext = element.businessObject.extensionElements;
    if (!ext) {
      return [];
    }

    let prop = getProperty(element.businessObject, id);

    if(!prop ||prop.length<1){
      return [];
    }
    return prop.value;
    //return element.businessObject.nameDE || '';
  }


  const setValue = value => {
    const extensionElements = element.businessObject.extensionElements || moddle.create('bpmn:ExtensionElements')
    let prop = getProperty(element.businessObject, id);

    if (!prop) {
      prop = moddle.create('modelervs:property', {name:id, value:value});
      extensionElements.get('values').push(prop);
    }
    prop.value = value;

   return modeling.updateProperties(element, {
     extensionElements
   });
  }

  const getOptions = () => {
    return [
        { label: '', value: '' },
        { label: 'Prescription / Vorschrift', value: 'provision' },
        { label: 'Modèle / Vorlage', value: 'template' },
        { label: 'Formulaire / Formular', value: 'form' },
        { label: 'Autre / Andere', value: 'other' },
    ]
  }
  //selectOptions : [
  //     { name: '', value: '' },
  //     { name: 'Provision', value: 'provision' },
  //     { name: 'Template', value: 'template' },
  //     { name: 'Form', value: 'form' },
  //     { name: 'Other', value: 'other' },
  // ]
  return <SelectEntry
    id={ id }
    element={ element }
    label={ translate(id) }
    getValue={ getValue }
    setValue={ setValue }
    getOptions = { getOptions }
    debounce={ debounce }
  />
}

export function getProperty(businessObject, type) {
  if (!businessObject.extensionElements) {
    return;
  }
  else if(!businessObject.extensionElements.values){
    businessObject.extensionElements.values=[];
    return;
  }
  return businessObject.extensionElements.values.filter((extensionElement) => {
    return extensionElement.name==type;
  })[0];
}

// Create the custom group
export function createGroup(groupName, element, translate) {

  // create a group for properties
  const group = {
    id: groupName,
    label: translate(_toUpper(groupName)),
    entries: []
  };

  return group
}
//helper for formatting
function _toUpper(phrase){

  return phrase.charAt(0).toUpperCase() + phrase.slice(1);
}

//Takes camel case string, returns
// thisNewString -> "This New String"
export function prettyLabel(camelCase){
  var words = camelCase.split(/(?=[A-Z])/);
  var first = words[0];
  var prettyout = "";

  prettyout = first[0].toUpperCase() +first.substring(1,first.length);

  for(let i = 1; i< words.length; i++){
    //If previous character is uppercase, i.e. if we're in the middle of an acronym

    if(prettyout[prettyout.length -1] == prettyout[prettyout.length -1].toUpperCase()){
      prettyout += words[i];
    }
    else{
      prettyout+= " "+words[i];
    }
  }

  return prettyout;
}
