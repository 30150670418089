// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  propertyIdentifier = 119,
  identifier = 120,
  nameIdentifier = 121,
  insertSemi = 122,
  LineComment = 1,
  BlockComment = 2,
  Expressions = 3,
  expression0 = 126,
  ForExpression = 4,
  forExpressionStart = 129,
  ForInExpressions = 6,
  ForInExpression = 7,
  Name = 8,
  Identifier = 9,
  AdditionalIdentifier = 10,
  minus = 11,
  plus = 12,
  divide = 13,
  multi = 14,
  inExpressionSep = 134,
  IterationContext = 16,
  forExpressionBodyStart = 137,
  IfExpression = 18,
  ifExpressionStart = 138,
  QuantifiedExpression = 22,
  quantifiedExpressionStart = 139,
  QuantifiedInExpressions = 25,
  QuantifiedInExpression = 26,
  Disjunction = 28,
  Conjunction = 30,
  Comparison = 32,
  PositiveUnaryTest = 36,
  PositiveUnaryTests = 38,
  ArithmeticExpression = 40,
  arithmeticPlusStart = 143,
  arithmeticTimesStart = 144,
  arithmeticExpStart = 145,
  arithmeticUnaryStart = 146,
  InstanceOfExpression = 42,
  Type = 45,
  QualifiedName = 46,
  VariableName = 47,
  SpecialType = 48,
  ContextEntryTypes = 61,
  ContextEntryType = 62,
  ArgumentTypes = 65,
  ArgumentType = 66,
  PathExpression = 67,
  pathExpressionStart = 151,
  FilterExpression = 69,
  filterExpressionStart = 152,
  FunctionInvocation = 71,
  functionInvocationStart = 153,
  SpecialFunctionName = 72,
  functionInvocationParameters = 154,
  NamedParameters = 73,
  NamedParameter = 74,
  ParameterName = 75,
  PositionalParameters = 76,
  literal = 157,
  nil = 158,
  NumericLiteral = 78,
  StringLiteral = 79,
  BooleanLiteral = 80,
  DateTimeLiteral = 81,
  DateTimeConstructor = 82,
  AtLiteral = 83,
  SimplePositiveUnaryTest = 85,
  Interval = 86,
  ParenthesizedExpression = 87,
  List = 88,
  FunctionDefinition = 89,
  functionDefinitionStart = 165,
  FormalParameters = 90,
  FormalParameter = 91,
  Context = 96,
  contextStart = 167,
  ContextEntry = 97,
  Key = 98,
  PropertyName = 99,
  PropertyIdentifier = 100,
  UnaryTests = 101
