import { styleTags, tags as t } from '@lezer/highlight';

export const feelHighlighting = styleTags({
  StringLiteral: t.string,
  NumericLiteral: t.number,
  BooleanLiteral: t.bool,
  'AtLiteral!': t.special(t.string),
  CompareOp: t.compareOperator,
  ArithOp: t.arithmeticOperator,
  'for if then else some every satisfies between return': t.controlKeyword,
  'in instance of and or': t.operatorKeyword,
  function: t.definitionKeyword,
  as: t.keyword,
  'Type/...': t.typeName,
  Wildcard: t.special,
  null: t.null,
  LineComment: t.lineComment,
  BlockComment: t.blockComment,
  'VariableName! "?"': t.variableName,
  'DateTimeConstructor! SpecialFunctionName!': t.function(t.special(t.variableName)),
  'List Interval': t.list,
  Context: t.definition(t.literal),
  'Name!': t.definition(t.variableName),
  'Key/Name! ContextEntryType/Name!': t.definition(t.propertyName),
  'PathExpression/VariableName!': t.function(t.propertyName),
  'FormalParameter/ParameterName!': t.function(t.definition(t.variableName)),
  '( )': t.paren,
  '[ ]': t.squareBracket,
  '{ }': t.brace,
  '.': t.derefOperator,
  ', ;': t.separator,
  '..': t.punctuation
});